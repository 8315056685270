
import qs from 'qs'
import { Component, Vue, Prop, Watch } from 'nuxt-property-decorator'
import { Events } from '@pp-frontend/shared-packages/types/enum/Events'
import SearchFormCtc from '~/components/search-form/SearchFormCtc.vue'
import { ErrorValidation } from '~/types/ErrorValidation'

@Component({
  components: {
    SearchFormGrz: () => import('~/components/search-form/SearchFormGrz.vue'),
    SearchFormCtc,
    SearchFormVu: () => import('~/components/search-form/SearchFormVu.vue'),
    SearchFormUin: () => import('~/components/search-form/SearchFormUin.vue')
  }
})
export default class extends Vue {
  @Prop({
    type: Boolean,
    default: true
  }) changeRoute!: boolean

  tabIndex: number = 1
  dataErrorValidations: any = {}
  errorDocVal: string = ''
  allowServices = this.$allowServices()

  @Watch('allowServices', { deep: true })
  allowServicesHandler () {
    if (!this.allowServices.ctc_by_grz && this.tabIndex === 1) {
      this.tabIndex = 2
    }
    return this.allowServices
  }

  mounted () {
    // @ts-ignore
    this.$eventBus.$on(Events.DATA_ERROR_FROM_VALIDATION, (error) => {
      this.dataErrorValidations = error
      this.errorDocVal = this.dataErrorValidations.error.response.data.error.document_value[0]
    })
  }

  tabClick (id: string) {
    if (!this.changeRoute) {
      this.activateByRoute(id)
      return
    }
    const path = '/' + id + (Object.keys(this.$route.query).length ? '?' + qs.stringify(this.$route.query) : '')
    if (this.$route.fullPath !== path) {
      this.$router.replace(path)
    }
  }

  created () {
    this.activateByRoute(this.$route.path)
  }

  activateByRoute (id: string) {
    switch (id) {
      case '/by-licence-plate':
        this.tabIndex = 1
        break
      case '/by-sts':
        this.tabIndex = 2
        break
      case '/by-vu':
        this.tabIndex = 3
        break
      case '/by-uin':
        this.tabIndex = 4
        break
      default:
        this.tabIndex = 1
    }
  }

  getErrorFormValidations (type: string): {} | ErrorValidation {
    if (process.client && this.errorDocVal) {
      return type === this.dataErrorValidations.payerData[0].document_type
        ? {
            isError: true,
            msgError: this.errorDocVal
          }
        : {
            isError: false,
            msgError: ''
          }
    }

    return {
      isError: false,
      msgError: ''
    }
  }
}
