
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Component, Vue } from 'nuxt-property-decorator'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import DocWidgetList from '@pp-frontend/shared-packages/components/Doc/WidgetList/DocWidgetList.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import ArticlesThree from '@pp-frontend/shared-packages/components/Articles/ArticlesThree.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import SearchForm from '~/components/SearchForm.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Banner from '~/components/Banner.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Statistics from '~/components/blocks-info/Statistics.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Features from '~/components/blocks-info/Features.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import CheckFinesInfo from '~/components/blocks-info/index/CheckFinesInfo.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import PaymentFinesInfo from '~/components/blocks-info/index/PaymentFinesInfo.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import GuaranteeInfo from '~/components/blocks-info/index/GuaranteeInfo.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import MobileAppInfo from '~/components/blocks-info/index/MobileAppInfo.vue'
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import FnsIndexWidgets from '~/components/index/FnsIndexWidgets.vue'

@Component({
  components: {
  FnsIndexWidgets,
  MobileAppInfo,
  GuaranteeInfo,
  PaymentFinesInfo,
  CheckFinesInfo,
  Features,
  Statistics,
  SearchForm,
  Banner,
  DocWidgetList,
  ArticlesThree
  }
  })
export default class extends Vue {
  head () {
    return {
      title: 'Проверка и оплата штрафов ГИБДД со скидкой 50% | ПогаситьШтраф.рф',
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: 'Полная информация о наличии штрафов из базы данных ГИБДД и ГИС ГМП. Онлайн-оплата картой любого банка со скидкой 50%. Поиск по СТС, гос номеру авто, УИН или водительскому удостоверению. Квитанция об погашении. Узнать о штрафах за превышение скорости, тонировку, неправильную парковку и другие'
        },
        {
          hid: 'author',
          name: 'author',
          content: 'Погаситьштраф.рф Сервис онлайн оплаты штрафов ГИБДД'
        }
      ],
      link: [
        { rel: 'prefetch', as: 'image', href: require('~/assets/img/popup/ic_outline-star-outline.svg') }
      ]
    }
  }

  beforeMount () {
    window.addEventListener('load', () => {
      this.$analytics.sendEvent('main')
    }, { once: true })
  }

  mounted () {
    /**
     * Сделано для перехода с писем, когда появилась новая база с пользователями,
     * чтобы сохранить email, с которого они пришли
     */
    if (this.$route.query.email) {
      this.$axios.patch(process.env.API_ENDPOINT + 'user', { email: this.$route.query.email })
    }
  }
}
